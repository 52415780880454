@import 'styles/utils';

.group.group {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.radio.radio {
  display: inline-flex;
  width: 100%;
  margin: 0;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  :global(.MuiRadio-root) {
    display: none;
  }
}

.wrapper {
  &:first-of-type {
    width: 35%;
  }
  width: 65%;

  text-align: center;
  cursor: pointer;
  :global(.MuiRadio-root) {
    display: none;
  }
  :global(.MuiFormControlLabel-label) {
    border-radius: 2px;
    font-size: 16px;
    font-weight: bold;
    height: 30px;
    color: $grey-60;
    display: grid;
    place-content: center;
  }
  :global(.Mui-checked + .MuiFormControlLabel-label) {
    color: $black;
  }
}
.categoryIcon {
  padding-right: 5px;
  height: 24px;
}
.categoryWrapper {
  display: flex;
  align-items: center;
}

.oneWayDistanceBox {
  height: 24px;
  line-height: 24px;
  color: $grey-60;
  margin-top: -1px;
}

.anchorLink {
  position: relative;
  span {
    position: absolute;
    top: -65px;
  }
}
